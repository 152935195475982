<template>
    <div class="about">
        <div class="navtop" style="overflow: hidden; position: relative;">
            <!-- 走马灯 轮播图-->
            <ComCarousel ref="ComCarousel"></ComCarousel>
			
        </div>
		
		<div>
			<HomeRoll ref='HomeRoll'></HomeRoll>
		</div>
		
        <div class="index-center">
            <div class="bottom">
                <div class="bottom-top">
                    <!-- 底部小图片显示部分 -->
                    <a>模型推荐</a> 
					<el-row style="margin-top: 22px;">
						<el-col :span='4'>
							<el-radio-group v-model="radio1" @change="one_radio_group">
							  <el-radio label='10'>热门下载</el-radio>
							  <el-radio label="20">最新上传</el-radio>
							</el-radio-group>
							
						</el-col>
						<el-col :span='1' :push="19">
							<p style="color:#b6492c; cursor: pointer;" @click="moxin">更多》</p>
						</el-col>
					</el-row>
                    <ComResourceInfoList1
                        ref="ComResourceInfoList1Good"
                    ></ComResourceInfoList1>
                </div>
            </div>
        </div>
		<div class="index-center">
		    <div class="bottom">
		        <div class="bottom-top">
		            <!-- 底部小图片显示部分 -->
		            <a>动画推荐</a> 
					<el-row style="margin-top: 22px;">
						<el-col :span='4'>
							<el-radio-group v-model="radio2" @change="tow_radio_group">
							  <el-radio label="10">热门下载</el-radio>
							  <el-radio label="20">最新上传</el-radio>
							</el-radio-group>
						</el-col>
						<el-col :span='1' :push="19">
							<p style="color:#b6492c; cursor: pointer;" @click="donghua">更多》</p>
						</el-col>
					</el-row>
		            <ComResourceInfoList1
		                ref="ComResourceInfoList2Good"
		            ></ComResourceInfoList1>
		        </div>
		    </div>
		</div>
		
		
		<div class="index-center">
		    <div class="bottom">
		        <div class="bottom-top">
		            <!-- 底部小图片显示部分 -->
		            <a>脚本推荐</a> 
					<el-row style="margin-top: 22px;">
						<el-col :span='4'>
							<el-radio-group v-model="radio3" @change="san_radio_group">
							  <el-radio label="10">热门下载</el-radio>
							  <el-radio label="20">最新上传</el-radio>
							</el-radio-group>
						</el-col>
						<el-col :span='1' :push="19">
							<p style="color:#b6492c; cursor: pointer;" class="jiaoben"@click="jiaoben">更多》</p>
						</el-col>
					</el-row>
		            <ComResourceInfoList1
		                ref="ComResourceInfoList3Good"
		            ></ComResourceInfoList1>
		        </div>
		    </div>
		</div>
    </div>
</template>
<script>
import {
    requestNavBar_10,
    requestNavBar_20,
    requestBanner,
    requestAddlist,
	resource_recommend,
	user_fabulous
} from "../api/request"; 

import HomeRoll from "../components/HomeRoll";
import ComResourceInfoList1 from "../components/ComResourceInfoList1";
import ComResourceInfoList from "../components/ComResourceInfoList";
import ComCarousel from "../components/ComCarousel";

export default {
    name: "Home",
    data() {
        return {
			radio1:'10',
			radio2:'10',
			radio3:'10',
			fabulous:null
		};
    },
    components: {
        ComCarousel,
        ComResourceInfoList1,
        ComResourceInfoList,
		HomeRoll
    },
	created(){
		var determine = localStorage.getItem('determine')
		// console.log(determine)
		if(determine == 'true'){
			localStorage.setItem("determine", false);
			// console.log(localStorage.getItem('determine'),111)
			history.go(0)
		}
		
	},
    //函数方法
    methods: {
        /**
         * 初始化数据
         *  最新素材
         *  优秀素材
         *  走马灯轮播图数据
         */
        initial_datas: function() {
            let data_this = this;
			let data1={
				state:10,
				resource_type:1
			}
            resource_recommend(data1).then(function(e) {
                data_this.$refs.ComResourceInfoList1Good.init(e.ret,data_this.fabulous);
            });
			let data2={
				state:10,
				resource_type:2
			}
			resource_recommend(data2).then(function(e) {
			    data_this.$refs.ComResourceInfoList2Good.init(e.ret,data_this.fabulous);
			});
			let data3={
				state:10,
				resource_type:3
			}
			resource_recommend(data3).then(function(e) {
			    data_this.$refs.ComResourceInfoList3Good.init(e.ret,data_this.fabulous);
			});
            requestBanner().then(function(e) {
                data_this.$refs.ComCarousel.init(e.ret);
            });
			
        },
		one_radio_group(){
			console.log(this.radio1)
			let _this=this;
			let data1={
				"state":parseInt(this.radio1),
				resource_type:1
			}
			resource_recommend(data1).then(function(e) {
			    _this.$refs.ComResourceInfoList1Good.init(e.ret,_this.fabulous);
			});
		},
		tow_radio_group(){
			let _this=this;
			let data2={
				"state":parseInt(this.radio2),
				resource_type:2
			}
			resource_recommend(data2).then(function(e) {
			    _this.$refs.ComResourceInfoList2Good.init(e.ret,_this.fabulous);
			});
		},
		san_radio_group(){
			let _this=this;
			let data1={
				"state":parseInt(this.radio3),
				resource_type:3
			}
			resource_recommend(data1).then(function(e) {
			    _this.$refs.ComResourceInfoList3Good.init(e.ret,_this.fabulous);
			});
		},
		moxin(){
			this.$router.push({
				path: '/ModeBase',
				query: {
					level_0: 10001,
					parentnodeid: 562949953421,
					sequence: 1,
					resource_type: 1
				}
			})
		},
		donghua(){
			this.$router.push({
				path: '/ModeBase',
				query: {
					level_0: 10005,
					parentnodeid: 1125899906842624,
					sequence: 2,
					resource_type: 2
				}
			})
		},
		jiaoben(){
			this.$router.push({
				path: '/ModeBase',
				query: {
					level_0: 10005,
					parentnodeid: 1125899906842624,
					sequence: 2,
					resource_type: 3
				}
			})
		}
    },
    mounted() {
		let _this=this;
		let token_nav= this.Account.GetToken();
		console.log(token_nav)
		if(this.Account.GetSession()!=null){
			requestAddlist(0).then(res=>{})
		}
		if(token_nav==null){
			_this.initial_datas();
			return
		}else{
			let datas={}
			user_fabulous(datas).then(res=>{
				_this.initial_datas();
				_this.fabulous=res;
			})
		}
		
        
		
    },
};
</script>
<style scoped>
@import "../style/common.css";

.navtop img {
    width: 1920px;
    height: 650px;
	
}
.index-center {
    background-color: #ffffff;
}

.bottom {
    /* width: 1229px; */
    width: 73%;
    background-color: #ffffff;
    /* height: 626px; */
    margin: 0 auto;
}
.bottom-top {
    padding-top: 96px;
    /* padding-left: 118px; */
    padding-bottom: 20px;
}
.bottom-wai {
    padding-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.bottom-box {
    background-color: #fff;
    width: 204px;
    margin-right: 10px;
    margin-top: 10px;
}
.bottom-box:hover img {
    background: rgba(0, 0, 0, 0.125);
    transform: scale(1.5);
    transition: 1s;
}
.bottom-box img {
    width: 100%;
}
.bottom-box p,
span {
    font-size: 14px;
    color: #b6b6b6;
}
.bottom-box p {
    padding-left: 10px;
}
.bottom-di {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
}
.list{
	position: absolute;
	top: 0px;
	right: 200px;
}
>>>.el-radio__input.is-checked  .el-radio__inner{
		background:#b6492c;
		border-color:#b6492c;
}
>>>.el-radio__input.is-checked+.el-radio__label{
		color:#a3a3a3;
	}
>>>.el-radio__label{
	color:#a3a3a3;
}
</style>




<!-- 
职位描述：
1、根据产品和设计方面需求，负责产品页面切图制作，交互效果代码实现;
2、负责产品的前端开发，能够把界面原型还原；
3、负责修改维护公司项目；
4、根据产品需求，从技术的角度进行评估，并给出建议，推动用户体验的优化。
5、配合后台开发人员实现产品功能，独立完成Web前端开发任务。
任职要求：
1、本科及以上学历，两年以上相关工作经验；
2、精通HTML5/CSS3/Javascript/vueJs等主流WEB前端技术；
3、精通DIV+CSS布局的HTML代码编写，能够书写符合W3C标准的代码，并有严苛的编码风格和良好的编码习惯；
4、精通jQuery等主流的js库，并能够对其特性和应用有深入的了解；
5、精通微信小程序开发原理、流程，接口组件、验证授权、支付等；
6、熟练切图，将UI设计转化为符合W3C规范的DIV+CSS静态页面，确保浏览器及平台的性能和兼容性；
7、熟悉主流开发框架及组件库，如vueJs、ReactJs，,有elementUI、AntDesign等组件库使用经验.
8、对MVVM架构了解清晰，熟悉vue生命周期函数，vue组件间参数传递方法，vue路由实现，掌握vuex原理。
9、思路清晰，善于思考，能独立分析和解决问题；
10、热爱本职工作，工作细心、责任心强。 -->