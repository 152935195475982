<template>
	<div class="ComResourceInfoList1">
		<el-col v-for="(goodsData1,index) in resource_info_list" style="position: relative; width: 19%;" class='hoverbox'>
			<div style="position:absolute;">
				<p v-show='goodsData1.type_id==0' style="width: 20px;"><img src="../assets/img/UserIcon.png" alt="" style="width: 100%;"></p>
				<p v-show='goodsData1.is_ci==1' style="width: 20px;"><img src="../assets/img/qiyeIcon.png" alt="" style="width: 100%;"></p>
				<p v-show='goodsData1.type_id==1 && goodsData1.is_ci!=1' style="width: 20px;"><img src="../assets/img/guanfnagIcom.png"
					 alt="" style="width: 100%;"></p>
			</div>
			<el-col class='box'>
				<el-col :span="24" style='height: 183px; background: #e6e6e6; overflow: hidden; position: relative;'>
					<div class="imgbox">
						<img :src="common.retun_img(goodsData1.thumbnail)" style="width: 80%;margin: 0 auto; display: block; "
						 oncontextmenu="return false;" @click="toDetail(goodsData1.id)">
					</div>
					<el-tooltip class="item" effect="dark" :content="goodsData1.webfabulous==1? '取消点赞' : '点赞'" placement="top">
						<div style="position: absolute; right: 6px; top: 6px; cursor: pointer;" @click="fabulous(goodsData1)">
							<p><img :src=" goodsData1.webfabulous==1?imgurlhover:imgurl" alt="" style="width: 20px;"></p>
							<p style="font-size: 12px; color: #b6b6b6; text-align: center;">{{goodsData1.fabulous}}</p>
						</div>
					</el-tooltip>
				</el-col>
				<el-col class="listbuttom_data">
					<div :title="goodsData1.name" class="list_top_data">
						<span style="font-size: 16px; color: #000000;" class="text_over">{{goodsData1.name}}</span> <span>{{goodsData1.author}}</span>
					</div>
					<div style="height: 41px;line-height: 41px;color: #787777;" class='xiazai'>
						<span>{{goodsData1.goldcoin}}币</span>
						
						<div style="display: flex;">
							<span style="display: flex;margin-right: 10px;" ><i class="icon_view"></i>{{goodsData1.look_number}}</span><span style="display: flex;">
							<i class="icon_dowm"></i>{{goodsData1.download_number}}</span>
						</div>

						<div class="tran_buttom">
							<span @click='btn_click_collect(goodsData1)' style="cursor: pointer;" class='shoucang'>
								<p><img src="../assets/img/xiazai.svg" alt="" width="24px" class="bottom_icon"></p> <span class="texthover">{{goodsData1.resource_type==1 ? '购买使用':'购买下载'}}</span>
							</span>
							<span @click="Shopping(goodsData1.id)" style="cursor: pointer; border-left: 1px solid rgba(255,255,255,0.5); border-right: 1px solid rgba(255,255,255,0.5);"
							 class='shoucang'>
								<p><img src="../assets/img/gowuche.svg" alt="" width="24px;" class="bottom_icon"></p> <span class="texthover">购物车</span>
							</span>
							<span @click='btn_click_collect(goodsData1)' style="cursor: pointer;" class='shoucang'>
								<p><img src="../assets/img/shocang.svg" alt="" width="24px" class="bottom_icon"></p> <span class="texthover">收藏</span>
							</span>

						</div>
					</div>
				</el-col>
			</el-col>
		</el-col>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
			<el-select v-model="value" placeholder="请选择收藏的文件夹">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<el-input style="width:320px; margin-right: 10px; margin-top: 20px;" placeholder="请输入内容" maxlength=8 v-model="add_ProjectList_input"></el-input>
			<el-button @click='addfolder'>添加文件夹</el-button>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="selection_box()">确 定</el-button>
			</span>
		</el-dialog>
		<!--<login  v-show="showlogin" @closeDialog="closeDialog"></login>-->
	</div>
</template>
<script>
	/*
    使用组件：
        <ComResourceInfoList1 ref="ComResourceInfoList1"></ComResourceInfoList1>
        let data = [
                {
                    account_id: 2070
                    goldcoin: 0
                    id: 5435
                    introduce: "无"
                    label: null
                    look_number: 552
                    name: "普通门2"
                    thumbnail: "79676c9ae137b71b3ca8c84befcc7589.png"
                },
            ];
        this.$refs.ComResourceInfoList1.init(data);
*/
	import {
		requestdirectory_list,
		requestaccount_collect_add,
		requestAddpush,
		requestdirectory_add,
		add_fabulous,
		del_fabulous,
		user_fabulous,
		resource_recommend

	} from "../api/request";
	export default {
		data() {
			return {
				resource_info_list: [],
				//收藏弹出框
				dialogVisible: false,
				//select选择框
				options: [],
				//选择框绑定
				value: '',
				//资源数据
				resource_data: '',
				showlogin: '',
				add_ProjectList_input: '',
				Child_list: '',
				current_select_level1_item: null,
				imgurl: require('../assets/img/dianzan.svg'),
				imgurlhover: require('../assets/img/dianzanhover.svg')
			};
		},
		components: {
			// login,
		},
		//函数方法
		methods: {
			//初始化数据
			init: function(data, fabulous) {
				console.log(fabulous)
				let token_nav = this.Account.GetToken();
				if (token_nav == null) {
					this.resource_info_list = data;
					// this.$parent.logo_open();
					return
				} else {
					for (let h = 0; h < fabulous.ret.length; h++) {
						for (let i = 0; i < data.length; i++) {
							if (fabulous.ret[h] == data[i].id) {
								data[i].webfabulous = 1;
							}
						}
					}
					this.resource_info_list = data;
				}

			},
			//点击图片
			image_click(item) {
				if (this.Account.GetSession() == null) {
					this.$router.push('SignIn')
					return
				}
				this.$router.push({
					path: "/ModeResources",
					query: {
						id: item
					}
				});
			},
			closeDialog(data) {
				this.showlogin = data;
			},
			toDetail(id) {
				if (this.Account.GetSession() == null) {
					this.$router.push('SignIn')
					return
				}
				this.$router.push({
					path: "/ModeResources",
					query: {
						id
					}
				});
			},
			//收藏
			btn_click_collect(item) {
				if (this.Account.GetSession() == null) {
					this.$router.push('SignIn')
					return
				}
				this.dialogVisible = true;
				this.resource_data = item
				let data = {
					parent_id: 0
				};
				requestdirectory_list(data).then(res => {
					this.current_select_level1_item = res.ret[1];
					// 一级列表赋值
					let datas = {
						parent_id: res.ret[1].id
					};
					requestdirectory_list(datas).then(res => {
						this.Child_list = res.ret;
						this.options = [];
						console.log(res)
						//二级列表赋值
						for (let i = 0; i < res.ret.length; i++) {
							this.options.push({
								value: res.ret[i].node_id,
								label: res.ret[i].name
							})

						}
					});
				});
			},
			//加购
			Shopping(e) {
				if (this.Account.GetSession() == null) {
					this.$router.push('SignIn')
					return
				}
				let _this = this;
				requestAddpush(e).then(function(res) {
					if (res.code == 0) {
						_this.$message({
							message: '加入购物车成功',
							type: 'success'
						});
					} else if (res.code == 5012) {
						_this.$confirm('BIM模型库中的企业CI库资源与BIM动画库中资源非会员不可购买！是否前往购买会员？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							_this.$router.push("/VipPrivilge")
						}).catch(() => {
							_this.$message({
								type: 'info',
								message: '已取消前往购买'
							});
						});
					}
				})
			},
			//添加文件夹
			addfolder() {
				if (this.add_ProjectList_input == '') {
					this.$message({
						message: '请填写项目名称',
						type: 'warning'
					});
					return
				};
				//获取最大的sequence
				let max_sequence = 0;
				for (let h = 0; h < this.Child_list.length; h++) {

					if (this.Child_list[h].sequence > max_sequence) {
						max_sequence = this.Child_list[h].sequence;
					}
					if (this.Child_list[h].name == this.add_ProjectList_input) {

						this.$message({
							message: '不可添加重复文件夹名称',
							type: 'warning'
						});
						return
					}
				};
				let sequence = max_sequence + 1;
				let node_idstr = this.common.retun_getnodeid(
					this.current_select_level1_item.node_id,
					2,
					sequence
				);
				let addData = {
					name: this.add_ProjectList_input,
					parent_id: this.current_select_level1_item.id,
					level: 2,
					sequence: sequence,
					node_id: node_idstr,
					parent_node_id: this.current_select_level1_item.node_id
				};
				//添加请求
				let _this = this;
				requestdirectory_add(addData).then((res) => {
					if (res.code == 0) {
						this.$message({
							message: '添加成功',
							type: 'success'
						});
						this.add_ProjectList_input = ''
					}
					this.dialogVisible_add = false;
					let data = {
						parent_id: 0
					};
					requestdirectory_list(data).then(res => {
						// 一级列表赋值
						let datas = {
							parent_id: res.ret[1].id
						};
						requestdirectory_list(datas).then(res => {
							this.Child_list = res.ret;
							this.options = [];
							//二级列表赋值
							for (let i = 0; i < res.ret.length; i++) {
								this.options.push({
									value: res.ret[i].node_id,
									label: res.ret[i].name
								})
							}
						});
					});
				});

			},
			//
			selection_box() {
				if (this.value == '') {
					this.$message({
						type: 'info',
						message: `请选择文件夹`
					});
					return
				}
				this.dialogVisible = false;
				// data={"token":"","resource_id":int,"node_id":""}
				console.log(this.resource_data)
				console.log(this.value)
				let data = {
					"resource_id": this.resource_data.id,
					"node_id": this.value
				}
				requestaccount_collect_add(data).then(res => {

					console.log(res)

				})

			},
			//加购物
			btn_click_purchase(id) {
				if (this.Account.GetSession() == null) {
					this.$parent.$parent.logo_open();
					return
				}
				let data_this = this;
				requestAddpush(id).then(function(res) {
					console.log(res)
					if (res.code == 0) {
						data_this.$message({
							message: '加入购物车成功',
							type: 'success'
						});
					}
				})
			},
			//点赞
			fabulous(item) {
				let token_nav = this.Account.GetToken();
				let _this = this;
				let data = {
					resource_id: item.id
				}
				if (token_nav == null) {
					this.$router.push('SignIn')
					return
				}
				if (item.webfabulous == 1) {
					del_fabulous(data).then(res => {
						let data3 = {
							"state": 10,
							resource_type: item.resource_type
						}
						resource_recommend(data3).then(function(e) {
							let datas = {}
							user_fabulous(datas).then(res => {
								_this.init(e.ret, res);
							})

						});
					})

				} else {
					add_fabulous(data).then(res => {
						let data3 = {
							"state": 10,
							resource_type: item.resource_type
						}
						resource_recommend(data3).then(function(e) {
							let datas = {}
							user_fabulous(datas).then(res => {
								_this.init(e.ret, res);
							})
						});
					})
				}

			},
			
			
		},
		mounted() {
			let start_time=Date.parse(new Date());
			console.log(start_time.toString().slice(0,10))
		},
	};
</script>

<style scoped>
	@import "../style/common.css";

	.navtop img {
		width: 1920px;
		height: 650px;
	}

	.index-center {
		background-color: #d1e0f9;
	}

	.ComResourceInfoList1 {
		padding-top: 20px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.resource-box {
		background-color: #fff;
		width: 251px;
		margin-right: 10px;
		margin-top: 10px;
	}

	.resource-box:hover img {
		background: rgba(0, 0, 0, 0.125);
		transform: scale(1.5);
		transition: 1s;
	}

	.resource-box img {
		width: 100%;
	}

	.resource-box p,
	span {
		font-size: 14px;
		color: #b6b6b6;
	}

	.resource-box p {
		padding-left: 10px;
	}

	.resource-words {
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		padding: 0 10px;
		display: flex;
		justify-content: space-between;
	}

	.shaixuan {
		background: #fff;
	}

	.active_sort {
		color: #007bff;
	}

	.shaixuan>div {
		height: 50px;
		line-height: 50px;
		border-bottom: 1px dashed #ccc;
	}

	.choose {
		color: #409EFF !important;
	}

	/* .hoverbox:hover{background-color:#cad8ed;} */

	.hoverbox {
		width: 263px;
		height: 266px;
		border-radius: 10px;
		overflow: hidden;
		margin-right: 12px;
		margin-bottom: 50px;
	}

	.box {
		background: #fff;
	}

	.box.imgbox img {
		transform: scale(0.5);
	}

	.hoverbox:hover {
		box-shadow: 0px 0px 6px 0px rgba(196, 62, 27, 0.75);
	}

	.hoverbox:hover .tran_buttom {
		bottom: 0px;
	}

	.box .imgbox {
		/* transform: translateY(calc(50% - 55px)); */
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		height: 183px;
		overflow: hidden;
		cursor: pointer;
	}

	.xiazai {
		justify-content: space-between;
		display: flex;
		margin-left: 6px;
		margin-right: 6px;
	}

	.box:hover .imgbox {
		overflow: hidden;
	}

	.box:hover .imgbox img {
		transform: scale(1.2);
		transition: 1s;
	}

	.texthover {
		display: block;
		color: white;
		text-align: center;
	}

	.list_top_data {
		height: 41px;
		line-height: 41px;
		border-bottom: 1px solid #ebebeb;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: #787777;
		justify-content: space-between;
		display: flex;
		margin-right: 6px;
		margin-left: 6px;
	}

	.listbuttom_data {
		position: relative;
		background-color: #f8f8f8;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
	}

	.tran_buttom {
		width: 100%;
		display: flex;
		position: absolute;
		bottom: -83px;
		left: 0px;
		transition: 0.5s;
	}

	.shoucang {
		width: 88px;
		height: 83px;
		background-color: rgba(196, 62, 28, 0.9);
		display: block;

	}

	.shoucang:hover {
		background-color: rgba(255, 146, 98, 0.9);
	}

	.bottom_icon {
		margin: 15px auto 0 auto;
		display: block;
	}

	.text_over {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.icon_view {
		margin-top: 10px;
		margin-right: 5px;
		width: 20px;
		height: 20px;
		display: block;
		background: url(../assets/img/view.svg) no-repeat center center;
		background-size: 100% 100%;
	}

	.icon_dowm {
		margin-top: 10px;
		margin-right: 5px;
		width: 20px;
		height: 20px;
		display: block;
		background: url(../assets/img/dowm.svg) no-repeat center center;
		background-size: 100% 100%;
	}
</style>
