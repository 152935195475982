<template>
    <div class="ComResourceInfoList1" style="overflow: hidden;">
		
		<div style="position: relative;" class="">
			
			<div class='trans' ref='trans' @mouseover="tranhover" @mouseout="tranout">
				
				<el-col  v-for="(goodsData1,index) in resource_info_list"  class='hoverbox' style="padding: 15px 5px 15px 5px;" >
				    <el-col style="width:260px; display: block;" class='box'  :span="24">
				        <el-col :span="24" style='height: 183px; background: #e6e6e6;overflow: hidden;'>
							<div  class="imgbox"   @click="toDetail(goodsData1.id)" >
								<img style="width: 80%;margin: 0 auto; display: block; " oncontextmenu="return false;" :src="common.retun_img(goodsData1.thumbnail)" >
							</div>
				        </el-col>
				        <el-col :span="24" :title="goodsData1.name" style="height: 30px;line-height: 30px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;color: #787777;justify-content: space-between; display: flex;">
				            <span>{{goodsData1.name}}</span> <span>{{goodsData1.author}}</span>
				        </el-col>
				        <el-col :span="24"style="height: 30px;line-height: 30px;color: #787777;" class='xiazai'>
				            <span class="xiazaione">{{goodsData1.goldcoin}}币</span><span class="xiazaione">下载量：{{goodsData1.download_number}}</span>
				        	<span @click="Shopping(goodsData1.id)" style="cursor: pointer;"class='shoucang' >
				        		<img src="../assets/img/购物车空.png" alt="" width="18px" > <span class="texthover">加入购物车</span>
				        	</span>
				        	<span @click='btn_click_collect(goodsData1)' style="cursor: pointer;"class='shoucang' >
				        		<i class="el-icon-star-off"></i> <span class="texthover">收藏</span>
				        	</span>
				        </el-col>
				    </el-col>
				</el-col>
			</div>
			
		</div>
		<el-dialog
		  title="提示"
		  :visible.sync="dialogVisible"
		  width="30%">
		    <el-select v-model="value" placeholder="请选择收藏的文件夹">
		      <el-option
		        v-for="item in options"
		        :key="item.value"
		        :label="item.label"
		        :value="item.value">
		      </el-option>
		    </el-select>
			<el-input style="width:320px; margin-right: 10px; margin-top: 20px;" placeholder="请输入内容" maxlength=8 v-model="add_ProjectList_input"></el-input>
			<el-button @click='addfolder'>添加文件夹</el-button>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="selection_box()">确 定</el-button>
		  </span>
		</el-dialog>
<!--<login  v-show="showlogin" @closeDialog="closeDialog"></login>-->
    </div>
</template>
<script>
/*
    使用组件：
        <ComResourceInfoList1 ref="ComResourceInfoList1"></ComResourceInfoList1>
        let data = [
                {
                    account_id: 2070
                    goldcoin: 0
                    id: 5435
                    introduce: "无"
                    label: null
                    look_number: 552
                    name: "普通门2"
                    thumbnail: "79676c9ae137b71b3ca8c84befcc7589.png"
                },
            ];
        this.$refs.ComResourceInfoList1.init(data);
*/
import {requestdirectory_list ,
requestaccount_collect_add , 
requestAddpush ,
requestdirectory_add,
requestNavBar_10,
user_upload,
resource_sold
} from "../api/request";
export default {
    data() {
        return {
            resource_info_list: [],
			list_data_one:[],
			//收藏弹出框
			dialogVisible:false,
			//select选择框
			options: [],
			//选择框绑定
			value: '',
			//资源数据
			resource_data:'',
			showlogin:'',
			add_ProjectList_input: '',
			Child_list: '',
			current_select_level1_item: null,
			timer_list:null,
			tiem:0,
			tiem_list:0,
			index:0,
			list_index:0
        };
    },
    components: {
        // login,
    },
    //函数方法
    methods: {
        //初始化数据
        init: function(data) {
            this.resource_info_list = data;
        },
        //点击图片
        image_click(item) {
            if(this.Account.GetSession()==null){
                this.$parent.$parent.logo_open();
                return
            }
            this.$router.push({ path: "/ModeResources", query:{id: item} });
        },
		// closeDialog(data) {
		//     this.showlogin = data;
		// },
		toDetail(id){
		    if(this.Account.GetSession()==null){
		        this.$parent.$parent.logo_open();
		        return
		    }
		    this.$router.push({ path: "/ModeResources", query: { id } });
		},
		list_details(item){
			if(this.Account.GetSession()==null){
			     this.$parent.$parent.logo_open();
			    return
			}
			let id = item.id
			this.$router.push({
				path: "/ModeResources",
				query: {
					id
				}
			})
		},
        //收藏
        btn_click_collect(item) {
			if(this.Account.GetSession()==null){
                this.$parent.$parent.logo_open();
				return
			}
            this.dialogVisible = true;
            this.resource_data=item
            let data = {
            	parent_id: 0
            };
            requestdirectory_list(data).then(res => {
				this.current_select_level1_item = res.ret[1];
            	// 一级列表赋值
            	let datas = {
            		parent_id: res.ret[1].id
            	};
            	requestdirectory_list(datas).then(res => {
					this.Child_list = res.ret;
            		this.options=[];
					console.log(res)
            		//二级列表赋值
            		for(let i=0; i<res.ret.length; i++){
            			this.options.push({value:res.ret[i].node_id,label:res.ret[i].name})

            		}
            	});
            });
        },
		//加购
		Shopping(e){
		    let _this=this;
		    requestAddpush(e).then(function (res) {
		        if(res.code==0){
		            _this.$message({
		                message: '加入购物车成功',
		                type: 'success'
		            });
		        }else if(res.code==5012){
                    _this.$confirm('BIM模型库中的企业CI库资源与BIM动画库中资源非会员不可购买！是否前往购买会员？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        _this.$router.push("/VipPrivilge")
                    }).catch(() => {
                        _this.$message({
                            type: 'info',
                            message: '已取消前往购买'
                        });
                    });
                }
		    })
		},
		//添加文件夹
		addfolder() {
			if (this.add_ProjectList_input == '') {
				this.$message({
					message: '请填写项目名称',
					type: 'warning'
				});
				return
			};
			//获取最大的sequence
			let max_sequence = 0;
			for (let h = 0; h < this.Child_list.length; h++) {

				if (this.Child_list[h].sequence > max_sequence) {
					max_sequence = this.Child_list[h].sequence;
				}
				if (this.Child_list[h].name == this.add_ProjectList_input) {

					this.$message({
						message: '不可添加重复文件夹名称',
						type: 'warning'
					});
					return
				}
			};
			let sequence = max_sequence + 1;
			let node_idstr = this.common.retun_getnodeid(
				this.current_select_level1_item.node_id,
				2,
				sequence
			);
			let addData = {
				name: this.add_ProjectList_input,
				parent_id: this.current_select_level1_item.id,
				level: 2,
				sequence: sequence,
				node_id: node_idstr,
				parent_node_id: this.current_select_level1_item.node_id
			};
			//添加请求
			let _this = this;
			requestdirectory_add(addData).then((res) => {
				if (res.code == 0) {
					this.$message({
						message: '添加成功',
						type: 'success'
					});
					this.add_ProjectList_input = ''
				}
				this.dialogVisible_add = false;
				let data = {
					parent_id: 0
				};
				requestdirectory_list(data).then(res => {
					// 一级列表赋值
					let datas = {
						parent_id: res.ret[1].id
					};
					requestdirectory_list(datas).then(res => {
						this.Child_list = res.ret;
						this.options = [];
						//二级列表赋值
						for (let i = 0; i < res.ret.length; i++) {
							this.options.push({
								value: res.ret[i].node_id,
								label: res.ret[i].name
							})
						}
					});
				});
			});

		},
		//
		selection_box(){
			if(this.value==''){
				this.$message({
				  type: 'info',
				  message: `请选择文件夹`
				});
				return
			}
			this.dialogVisible = false;
			// data={"token":"","resource_id":int,"node_id":""}
			console.log(this.resource_data)
			console.log(this.value)
			let data={
				"resource_id":this.resource_data.id,
				"node_id":this.value
			}
			requestaccount_collect_add(data).then(res=>{

				console.log(res)

			})

		},
        //加购物
        btn_click_purchase(id) {
            if(this.Account.GetSession()==null){
                this.$parent.$parent.logo_open();
                return
            }
            let data_this=this;
            requestAddpush(id).then(function (res) {
                console.log(res)
                if(res.code==0){
                    data_this.$message({
                        message: '加入购物车成功',
                        type: 'success'
                    });
                }
            })
        },
		settiem(){
			let _this=this;
			let element=_this.$refs.trans;
			
			this.timer=setInterval(()=>{
				_this.tiem+=100;
				
				let one_data=_this.resource_info_list[_this.index];
				_this.resource_info_list.push(one_data);
				if(_this.index<14){
					_this.index+=1;
				}else{
					_this.index=0;
				}
				element.style.transform='translateX(-'+_this.tiem+'px)'
			},1500)
		},
		tranhover(){
			clearInterval(this.timer)
		},
		tranout(){
			this.settiem();
		},
    },
    mounted() {
		
		setTimeout(()=>{
			this.settiem()
		});
    },
};
</script>

<style scoped>
@import "../style/common.css";

.navtop img {
    width: 1920px;
    height: 650px;
}
.index-center {
    background-color: #d1e0f9;
}

.ComResourceInfoList1 {
    padding-top: 20px;
    justify-content: flex-start;
}
.resource-box {
    background-color: #fff;
    width: 251px;
    margin-right: 10px;
    margin-top: 10px;
}
.resource-box:hover img {
    background: rgba(0, 0, 0, 0.125);
    transform: scale(1.5);
    transition: 1s;
}
.resource-box img {
    width: 100%;
}
.resource-box p,
span {
    font-size: 14px;
    color: #b6b6b6;
}
.resource-box p {
    padding-left: 10px;
}
.resource-words {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
}
 .shaixuan{
        background: #fff;
    }
    .active_sort{
        color: #007bff;
    }
    .shaixuan>div{
        height: 50px;line-height: 50px;
        border-bottom: 1px dashed #ccc;
    }
    .choose{color: #409EFF !important;}
	/* .hoverbox:hover{background-color:#cad8ed;} */
	.hoverbox:hover .box{background: #cad8ed;}
	.shoucang{
		display: none;
	}
	.hoverbox:hover .shoucang{
		display: block;
	}
	.hoverbox:hover .xiazaione{
		display: none;
	}
	.box{background: #fff;padding: 15px;}
	.box.imgbox img{
		transform: scale(0.5);
	}
	.box .imgbox{
		/* transform: translateY(calc(50% - 55px)); */
		display:table-cell;

		vertical-align:middle;

		text-align:center;
		height: 183px;
		overflow: hidden;
	}

	.xiazai{
		 justify-content: space-between; display: flex;
	}

	.box:hover .imgbox{

		overflow: hidden;
	}
	.box:hover .imgbox img{ transform: scale(1.2); transition: 1s;}
	.texthover:hover{
		color: royalblue;
	}
	.trans{
		display: flex;
		transition:2s;
		transform: translateX(0px);
	}
	.list{
		width: 260px;
		height: 288px;
		z-index: 80;
		background:rgb(0,0,0,0.8);
		overflow: hidden;
	}
	.list li{
		line-height: 25px;
		cursor: pointer;
	}
	.list p{color: white; font-size: 12px; display: block;overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;}
.tranlist{
	transform: translateY(0px);
	transition: 1S;
}
</style>
