<template>
    <div class="ComCarousel" id="banner">
        <div class="wrap block">
            <el-carousel
                class="tu"
                height="650px"
                trigger="click"
                ref="carousel"
            >
                <el-carousel-item
                    style="height: 650px;"
                    v-for="(item, index) in carousel_items"
                    :key="index"
                >
                    <img
                        :src="item.images"
						oncontextmenu="return false;"
                        :alt="item.description"
                        @click="open_link(item.url)"
                        style="width:100%;height:100%"
                    />
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
/*
    使用组件：
        <ComCarousel ref="ComCarousel"></ComCarousel>
        let data = [
                {
                    description: ""
                    id: 1
                    images: "https://res.bimshows.cn/res/rabbit/image/main/1.png"
                    url: "https://www.bimfilm.cn/"
                },
            ];
        this.$refs.ComCarousel.init(data);
*/
export default {
    components: {},
    data() {
        return {
            carousel_items: [],
        };
    },
    methods: {
        //初始化数据
        init:function(data)
        {
          this.carousel_items = data;
        },
        //点击图片打开链接
        open_link(url) {

            if ("" != url) {
                window.open(url);
            }
        },
    },
    mounted() {
    },
    computed: {},
    updated() {},
};
</script>


<style scoped>
.el-carousel--horizontal {
    overflow: inherit !important;
}

/*按钮不在正中间*/
.tu:nth-child(1) {
    height: 650px;
}
</style>
