<template>
	<div class="HomeRoll" ref='HomeRoll'>
		<el-row style="width: 73%; margin: 0 auto;overflow: hidden;">
			<!--  -->
			<el-col style="width: 33.333%;" class="HomeRollTitle" ref='HomeRollTitle'>
				<div>
					<p>今日新增资源 </p>
					<span>{{todayaddnumber}}</span>
				</div>
				<div>
					<p>网站累计资源 </p>
					<span>{{sourcetotalnumber}}</span>
				</div>
				<div>
					<p>网站用户 </p>
					<span>{{usernumber}}</span>
				</div>
			</el-col>
			<el-col style="width: 33.333%;" class="HomeRollVIPState" ref='HomeRollVIPState'>
				<h3>会员最新动态</h3>
				<ul>
					<li v-for="(item,index) in HomeRollVIPData" style="display: flex;" :class="index==0 ? 'one' : ''">
						<span style="width:80px; display: inline-block;" >{{item.author.substring(0,1) + new Array(item.author.length).join('*')}}</span> 购买 &nbsp; <span style="color: brown; cursor: pointer; display: block; width: 50%;" class="text_over" @click="godetails(item)" >{{item.name}}</span> <p style="float: right; text-align: left;">花费 {{item.goldcoin.split('.')[0]}}金币</p>
					</li>
				</ul>

			</el-col>
			<el-col style="width: 33.333%;" class="HomeRollSellState HomeRollVIPState" ref='HomeRollSellState'>
				<h3>资源收入排行</h3>
				<ul>
					<li v-for="(item,index) in HomeRollSellData" :class="index==0 ? 'one' : ''" style="display: flex;justify-content: space-between;">
						<span style="color:#c43d1d; cursor: pointer;" @click="godetailstow(item)" >{{item.name}}</span>
						<p >{{item.owner_goldcoin.split('.')[0]}}金币</p>
					</li>
				</ul>
			</el-col>
		</el-row>
		<el-row style="width: 73%; margin: 0 auto;">
			<el-col>
				<div ref='down' style="width: 50px;margin: 0 auto;transition: 0.5s;">
					<p class="down" @click="down"></p>
				</div>
			</el-col>
		</el-row>
	</div>

</template>
<script>
	import {
		requestNavBar_10,
		resource_sold,
		index_config
	} from '../api/request.js'
	export default {
		data() {
			return {
				HomeRollVIPData: '',
				HomeRollSellData: '',
				todayaddnumber: null,
				sourcetotalnumber: null,
				usernumber: null,

			};
		},
		components: {},

		methods: {
			down() {
				
				console.log(parseInt(this.$refs.HomeRoll.style.height))
				if(parseInt(this.$refs.HomeRoll.style.height)>399){
					this.$refs.HomeRollVIPState.$el.style.height='200px'
					this.$refs.HomeRollSellState.$el.style.height='200px'
					this.$refs.HomeRollTitle.$el.style.height='200px'
					this.$refs.HomeRoll.style.height='200px'
					this.$refs.down.style.transform='rotate(360deg)';
				}else{
					this.$refs.HomeRollVIPState.$el.style.height='400px'
					this.$refs.HomeRollSellState.$el.style.height='400px'
					this.$refs.HomeRollTitle.$el.style.height='400px'
					this.$refs.HomeRoll.style.height='400px'
					this.$refs.down.style.transform='rotate(180deg)'
				}
				
			},
			godetails(item){
				if (this.Account.GetSession() == null) {
					this.$router.push('SignIn')
					return
				}
				
				
				window.open("/#/ModeResources?id=" + item.id)
			},
			godetailstow(item){
				
				if (this.Account.GetSession() == null) {
					this.$router.push('SignIn')
					return
				}
				
				window.open("/#/ModeResources?id=" + item.resource_id)
			},
			compare: function(property) {
				return function(a, b) {
					var value1 = a[property];
					var value2 = b[property];
					return value2 - value1;
				}
			},


		},
		mounted() {
			let _this = this;
			requestNavBar_10().then(res => {
				this.HomeRollVIPData = res.ret;
			})
			resource_sold().then(res => {
				for (let i = 0; i < res.ret.length; i++) {
					res.ret[i].create_time = _this.common.retun_formatDate11(res.ret[i].create_time)
				}
				res.ret.sort(this.compare('owner_goldcoin'));
				this.HomeRollSellData = res.ret;
				
			})
			
			index_config().then(res => {
				console.log(res)
				_this.todayaddnumber = res.ret.todayaddnumber;
				_this.sourcetotalnumber = res.ret.sourcetotalnumber;
				_this.usernumber = res.ret.usernumber;
			})
		},
		destroyed() {},
		watch: {}
	};
</script>

<style scoped>
	@import "../style/common.css";

	.HomeRoll {
		width: 100%;
		transition: 0.6s;
		height: 200px;
		/* padding-left: 302px; */
		background-color: #f5f5f5;
	}

	.HomeRollTitle {
		padding-top: 20px;
		height: 200px;
		transition: 0.6s;
		border-right: 1px dashed #c7c7c7;
	}

	.HomeRollTitle div {
		display: flex;
		line-height: 20px;
		margin-bottom: 28px;
	}

	.HomeRollTitle div p {
		width: 26%;
		font-size: 14px;
		font-weight: bold;
		margin-right: 18px;
	}

	.HomeRollTitle div span {
		font-size: 20px;
		color: #c43e19;
	}

	.HomeRollVIPState {
		padding-left: 20px;
		transition: 0.6s;
		padding-top: 18px;
		height: 200px;
		border-right: 1px dashed #c7c7c7;
	}

	.HomeRollVIPState h3 {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 24px;
	}

	.HomeRollVIPState ul li {
		font-size: 14px;
		height: 29px;
		line-height: 29px;
		color: #676767;
		text-indent: 3px;
	}

	.HomeRollSellState {
		border: none;
		transition: 0.6s;
	}

	.one {
		height: 21px !important;
		line-height: 21px !important;
	}

	.down {
		width: 50px;
		height: 50px;
		cursor: pointer;
		margin: 10px auto 0;
		background: url(../assets/img/xiangxia.svg) no-repeat center center;
		background-size: 100% 100%;
		animation: rotate 1s linear infinite;
	}
	.down:hover{
		background: url(../assets/img/xiangxiatow.svg) no-repeat center center;
		background-size: 100% 100%;
	}
	@keyframes rotate {
	    0% {
	    transform: translateY(0) ;
	  }
	    25% {
	        transform: translateY(5px);
	    }
	    50% {
	        transform: translateY(10px);
	       
	    }
	    75% {
	        ransform: translateY(2px) ;
	    }
	    100% {
	        transform: translateY(0) ;
	    }
	}
</style>
